import {
  DiGit,
  DiJava,
  DiJsBadge,
  DiPostgresql,
  DiReact,
} from "react-icons/di";
import Icon from "./icon.component";
import "./IconBackground.style.scss";

export default function IconBackground() {
  return (
    <div className="icon-background" data-scroll data-scroll-speed="8">
      <Icon top="10%" right="80%">
        <DiGit />
      </Icon>
      <Icon top="1%" right="50%">
        <DiReact />
      </Icon>
      <Icon top="10%" right="20%">
        <DiJsBadge />
      </Icon>
      <Icon top="60%" right="30%">
        <DiPostgresql />
      </Icon>
      <Icon top="60%" right="70%">
        <DiJava />
      </Icon>
    </div>
  );
}
