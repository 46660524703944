import "./education.style.scss";
import pencilSvg from "../../assets/pencil.svg";
import Detail from "./detail.component";
import { useEffect, useRef } from "react";
import Observer from "../observer/observer.component";
import Pencils from "./pencils.component";
export default function Education({ children, handleInView }) {
  const sectionRef = useRef(null);

  return (
    <Observer sectionRef={sectionRef} callback={handleInView}>
      <section
        ref={sectionRef}
        id="education"
        className="education"
        data-scroll-section
        data-scroll
        data-scroll-class="purpleColor"
      >
        <Pencils />
        <div className="details">
          <div className="side-by-side">
            <div className="title" data-scroll data-scroll-speed={2}>
              <span>Fullstack</span>
              <span> Software </span>
              <span>Engineer</span>
            </div>
            <div className="who-am-i" data-scroll data-scroll-speed={5}>
              <span>
                I come from a background of <em>Information Technology</em> as I
                have a BA in Electronic Engineering. I have always been
                interesed in building things and that lead to my first job as a
                CNC machinist where I had to both maintain the software and
                hardware of machine tools. Upon graduating I decided that I much
                prefer software.
                <br />I have worked with many programming languages. At school
                we used <em>C</em> and <em>Java</em> which helped us grasp the
                foundations of programming and <em>OOP</em> but I personally
                prefer <em>JavaScript/Typescript</em> since I can build easily
                deployable software.
              </span>
            </div>
          </div>
        </div>
      </section>
    </Observer>
  );
}
