import "./contact.style.scss";
import Observer from "../observer/observer.component";
import {
  IoLogoGithub,
  IoGlobeOutline,
  IoLogoTwitter,
  IoMailSharp,
} from "react-icons/io5";
import { useRef } from "react";

function ContactLink({ icon, link, name }) {
  return (
    <a href={link} about={name} target="_blank" rel="noreferrer">
      <div className="link" data-scroll data-scroll-speed="2">
        {icon}
        {name}
      </div>
    </a>
  );
}
export default function Contact({ handleInView }) {
  const sectionRef = useRef(null);

  return (
    <Observer sectionRef={sectionRef} callback={handleInView}>
      <section
        className="contact"
        ref={sectionRef}
        id="contact"
        data-scroll-section
        data-scroll
        data-scroll-class="light-color"
      >
        <div className="links">
          <ContactLink
            name="Github"
            link="https://github.com/Hasan-aga"
            icon={<IoLogoGithub />}
          />
          <ContactLink
            name="My Blog"
            link="http://blog.hasan.one/"
            icon={<IoGlobeOutline />}
          />

          <ContactLink
            name="careerforma@gmail.com"
            link="mailto:careerforma@gmail.com?subject=I like your portfolio!&body=Hi"
            icon={<IoMailSharp />}
          />
        </div>
      </section>
    </Observer>
  );
}
