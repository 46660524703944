import { useEffect, useRef, useState } from "react";
import "./bar.style.scss";
import Highlight from "./highlight.component";
import Navlink from "./navlink.component";
export default function Bar({ currentViewID }) {
  // get current section in view
  // get position of corresponding navlink
  // store all navlinks in map
  // move highlight by offset of navlink[currentViewID]

  console.log("current view", currentViewID);

  return (
    <div className="navigation-bar">
      <Highlight currentViewID={currentViewID}>
        <div className="navigation-links">
          <Navlink elementID="#education" />
          <Navlink elementID="#projects" />
          <Navlink elementID="#contact" />
        </div>
      </Highlight>
    </div>
  );
}
