import { useState } from "react";
import Modal from "react-modal";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

export default function Card({ children, url }) {
  return (
    <div className="card">
      <Link className="card-link" target="_blank" to={url}>
        <div className="card-content">{children}</div>
      </Link>
    </div>
  );
}
