import { useEffect, useState } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { TypeAnimation } from "react-type-animation";
import Contact from "../contact/contact.component";
import Education from "../education/education.component";
import Intro from "../intro/Intro.component";
import Bar from "../navigation/bar.component";
import Projects from "../projects/projects.component";

function on(eventType, listener, scroll) {
  scroll.on(eventType, listener);
}

export default function Content() {
  const { scroll, isReady } = useLocomotiveScroll();
  const [currentView, setCurrentView] = useState(null);

  useEffect(() => {
    if (isReady) on("call", () => console.log("test event"), scroll);
  }, []);

  return (
    <>
      <Intro handleInView={setCurrentView}>
        <div className="type-vertical-stack">
          <div data-scroll data-scroll-speed="3" className="typemachine">
            <>&gt;</>
            <TypeAnimation
              sequence={[800, "Who Am I?"]}
              wrapper="div"
              cursor={true}
              repeat={false}
              style={{ fontSize: "1em" }}
            />
          </div>
          <div data-scroll data-scroll-speed="2" className="typemachine">
            <TypeAnimation
              sequence={[2800, "I am Hasan Aga."]}
              wrapper="div"
              cursor={false}
              repeat={false}
              style={{ fontSize: "1em" }}
            />
          </div>
          <div data-scroll data-scroll-speed="1" className="typemachine">
            <TypeAnimation
              sequence={[
                3500,
                "Fullstack Developer.",
                1000,
                "Scroll down for more 👀",
              ]}
              wrapper="div"
              cursor={false}
              repeat={Infinity}
              deletionSpeed={60}
              style={{ fontSize: "0.8em", lineHeight: "1.5" }}
            />
          </div>
        </div>
      </Intro>
      <Education handleInView={setCurrentView} />
      <Projects handleInView={setCurrentView} />

      <Contact handleInView={setCurrentView} />

      <Bar currentViewID={currentView} />
    </>
  );
}
