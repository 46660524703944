import IconBackground from "./IconBackground.component.js";
import "./intro.style.scss";

import { useRef } from "react";

export default function Intro({ children }) {
  const sectionRef = useRef(null);
  return (
    <section
      ref={sectionRef}
      id="intro"
      className="intro"
      data-scroll
      data-scroll-speed="4"
      data-scroll-section
    >
      {children}
      <IconBackground />
    </section>
  );
}
