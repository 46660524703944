import { useEffect, useRef } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import "./navlink.style.scss";

export default function Navlink({ elementID }) {
  const { scroll } = useLocomotiveScroll();
  const target = document.querySelector(elementID);

  return (
    <div className="navigation-link" id={`${elementID}Link`}>
      <button onClick={() => scroll.scrollTo(target)}>
        {elementID.slice(1)}
      </button>
    </div>
  );
}
