import { IoLogoGithub, IoLink } from "react-icons/io5";
import feedniLight from "../../assets/feedni-screen-full1.png";
import feedniDark from "../../assets/feedni-screen-full2.png";

export default function Feedni() {
  return (
    <div className="project-page">
      <div className="container">
        <div className="title"> Feedni </div>
        <div className="links">
          <a
            href="https://github.com/Hasan-aga/feedly_clone"
            title="View source code"
          >
            <IoLogoGithub />
          </a>
          <a href="https://feedni.hasan.one/" title="Visit site">
            <IoLink />
          </a>
        </div>
        <div className="description">
          <p>
            This is an app that I now use on daily basis. I knew that I was
            going to have fun building it but I what I did not know is how much
            I was going to learn.
          </p>
          <p>
            Creating this app not only involved using <em>NextJS</em> and{" "}
            <em>postgres</em> but I ended up contributing to an npm web-scraping
            package called feed-reader. I had to make decisions that involve
            balancing the user-experience with the costs of hosting. I learned a
            lot about RSS and Open-graph tags. I had to try and compare several
            providers for database hosting. Overall it was a positive
            experience.
          </p>
          <div className="subtitle"> Tech stack </div>
          <p>
            <em>NextJS</em> for the frontend/backend.
            <br />
            Next-Auth for authentication.
            <br />
            NextUI for UI compoenents.
            <br />
            <em>Postgres</em> for database.
          </p>
          <div className="subtitle"> Hosting</div>
          <p>
            Hosting{" "}
            <em>
              <em>NextJS</em>
            </em>{" "}
            on Vercel is a no-brainer. For the database I ended up using{" "}
            <em>Supabase</em>. I tried CockroachDB but their version of{" "}
            <em>Postgres</em>
            does not include Triggers.
          </p>
          <div className="subtitle"> Lessons learned</div>

          <p>
            During the course of development I tested several Html/RSS parsers.
            The one that stood out in terms of speed and minimal package size
            was <em>fast-xml-parser</em>. The one that had the best
            developer-experience was <em>node-html-parser</em>.
          </p>

          <p>
            Using a component library such as NextUI accelerated the development
            process. But it also meant that I had to adapt to the library's
            shortcomings. It felt limiting at times. In retrospect, NextUI is
            still in Beta so I should've gone for something more complete.
          </p>
        </div>
        <div className="images">
          <img src={feedniLight} alt="app screenshot light mode" />
          <img src={feedniDark} alt="app screenshot dark mode" />
        </div>
      </div>
    </div>
  );
}
