import React from "react";
import pencilSvg from "../../assets/pencil.svg";

function Pencils() {
  return (
    <div className="pencil-container">
      {[...Array(60)].map(() => (
        <div data-scroll data-scroll-speed={Math.random() * 2}>
          <img
            style={{
              transform: `rotate(${360 * Math.random()}deg)`,
              margin: Math.random() * 100,
            }}
            src={pencilSvg}
            alt="pencil"
          />
        </div>
      ))}
    </div>
  );
}

export default React.memo(Pencils);
