import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useRef } from "react";
import "./App.css";
import AnimatedCursor from "react-animated-cursor";
import Content from "./components/content/content.component";
import "./media-queries.scss";

export default function App() {
  console.log(`
               ____                             ______    
  |         | |            |        |         .~      ~.  
  |_________| |______      |        |        |          | 
  |         | |            |        |        |          | 
  |         | |___________ |_______ |_______  \`.______.\`  
  This site was designed and programmed by Hasan Aga`);

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
  }

  const ref = useRef(null);

  const options = {
    smooth: true,
  };

  return (
    <LocomotiveScrollProvider options={options} containerRef={ref}>
      <AnimatedCursor
        innerSize={18}
        outerSize={30}
        color="193, 11, 111"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        outerStyle={
          {
            // filter: "invert(50%)",
            // mixBlendMode: "difference",
          }
        }
        clickables={[
          "a",
          "h1",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
          ".card",
        ]}
      />
      <main className="target" data-scroll-container ref={ref}>
        <Content />
      </main>
    </LocomotiveScrollProvider>
  );
}
