import { useEffect, useRef, useState } from "react";
import "./highlight.style.scss";

export default function Highlight({ children, currentViewID }) {
  const ref = useRef(null);
  const [linkMap, setLinkMap] = useState(null);

  useEffect(() => {
    let linkMapTemp = {};
    const links = Array.from(ref.current.children[0].children);
    console.log(links[0].offsetParent.children[0].offsetLeft);
    for (const link of links) {
      linkMapTemp[link.outerText.toLowerCase()] =
        link.offsetLeft - link.offsetParent.children[0].offsetLeft;
    }
    setLinkMap(linkMapTemp);
  }, [ref]);

  return (
    <div ref={ref} className="highlight-container">
      {children}
      {linkMap && linkMap[currentViewID] && (
        <div
          className="highlight"
          style={{
            marginLeft: linkMap[currentViewID] + 35,
          }}
        >
          🔺
        </div>
      )}
    </div>
  );
}
