import "./bikerPhone.style.scss";
import mac3 from "../../assets/mac.jpg";
import mac2 from "../../assets/mac04.jpg";
import mac1 from "../../assets/mac03.jpg";
import { IoLinkSharp, IoLogoGithub } from "react-icons/io5";

function createImg(image) {
  return <img src={image} alt="app mockup" />;
}

export default function BikerRouter() {
  return (
    <div className="project-page">
      <div className="container">
        <div className="title"> Biker Phone</div>
        <div className="links">
          <a
            href="https://github.com/Hasan-aga/bike-router"
            title="View source code"
            target="_blank"
          >
            <IoLogoGithub />
          </a>
          <a
            href="https://biker.hasan.one/"
            title="view the app"
            target="_blank"
          >
            <IoLinkSharp />
          </a>
        </div>
        <div className="description">
          <p>
            I was watching an HTML/CSS course when I first learned about the
            browser's Geolocation API and I was struck by how simple it is.
            Right then I knew I had to make a side project that uses it and
            since I enjoy cycling, I decided to make an app that not only helps
            me find the best cycling route between two points on a map but also
            display valuable insights on the <em>elevation</em> and
            <em>inclination</em> along the path.
          </p>
          <p>
            I've found that the best way to truly learn any technology is to use
            it in a meaningful side-project. That is how you get motivation to
            finish the project; it holds a meaning to you.
          </p>
          <div className="subtitle"> My initial expectations </div>
          <p>
            I planned on creating a fullstack project where the backend would
            use openstreetmap data to calculate the best path between any two
            points on the map using Djikstra's algorithm.
          </p>
          <p>
            After playing around with the data of a small country such as
            Monaco, I realised that working with the full size of the data
            available is simply not possible without massive hardware upgrades.
            For example, the dev team behind openstreetmap explicitly asks to
            not even report issues if you run the software on anything less than{" "}
            <em>64GB of RAM!</em>
          </p>
          <p>
            After realising that I could not possibly host the backend myself, I
            turned to commercially available API's.
          </p>
          <div className="subtitle"> Development experience </div>
          <p>
            I would love to say that I had many challenges but that would not be
            accurate. I kept the project simple and did not use any 3rd party
            state management. Relied mostly on React context.
          </p>
          <p>
            I hindsight, I could have used prop drilling to get the job done
            with less code. The massice ecosystem of libraries that serve React
            and JavaScript made my task easier.
          </p>
          <p>
            This was my second <em>Typescript</em> project and I 100% did not
            regret using it. It was especially useful when working with the
            incoming data from the API as Typescript enabled hints and worked
            almost like documentation for my work.
          </p>
        </div>
        <div className="images">
          {[mac2, mac3, mac1].map((image) => createImg(image))}
        </div>
      </div>
    </div>
  );
}
