import "./bikerPhone.style.scss";
import bikerPhone1 from "../../assets/bikerPhone1.jpg";
import bikerPhone2 from "../../assets/bikerPhone2.jpg";
import bikerPhone3 from "../../assets/bikerPhone3.jpg";
import { IoLogoGooglePlaystore, IoLogoGithub } from "react-icons/io5";
import AnimatedCursor from "react-animated-cursor";

function createImg(image) {
  return <img src={image} alt="app mockup" />;
}

export default function BikerPhone() {
  return (
    <div className="project-page">
      <div className="container">
        <div className="title"> Biker Phone</div>
        <div className="links">
          <a
            href="https://github.com/Hasan-aga/bikerPhone"
            title="View source code"
          >
            <IoLogoGithub />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.bikerphone"
            title="Get the app"
          >
            <IoLogoGooglePlaystore />
          </a>
        </div>
        <div className="description">
          <p>
            I had already built a web app that does the job of finding a cycling
            path between two points on a map, but when I opened the web app on
            the mobile phone I realised that the user experience is terrible!
          </p>
          <p>
            The map images were being over-compressed by the browser. And the UI
            was not terribly good either. I hence decided to try{" "}
            <em>React Native</em> and see just how easy it is to create a mobile
            app.
          </p>
          <div className="subtitle"> My initial expectations </div>
          <p>
            I expected a lot from React Native. For starts, I thought I would be
            able to re-use some of my web app components that I had built with
            React. I also assumed that I'd have access to the same ecosystem of
            libraries that React/JavaScript have access to. Both of my
            expectations were not entirely met.
          </p>
          <div className="subtitle"> Bitter sweet experience </div>
          <p>
            React Native has a set of elementary components that -when built-
            turn into 100% native elements. This is great for both the
            performance and the look of the app but this also meant that very
            little re-use can be achieved between React and React Native.
          </p>
          <p>
            Another realization was that React Native has its own ecosystem. I
            couldn't use a React or JS library without adapting it. Likewise I
            didn't have access to native libraries without writing an adapter. I
            found myself in a <em>strange position</em>.
          </p>

          <p>
            Don't get me wrong. There is an ecosystem around React Native and
            many adapters and wrappers have been implemented for popular native
            or JS libraries. But this ecosystem is still much smaller than what
            I am used to work with.
          </p>
          <p>
            I experienced my first challenge when I added the chart component to
            the app. I had a limited number of chart libraries to pick from and
            most of them had terrible performance. The one I ended up using was
            an Android library and it too did not have all I needed. I wanted a
            way for the user to highlight a chunk of data on the chart and get
            insights about it. I ended up implementing that UI element from
            zero.
          </p>
        </div>
        <div className="images">
          {[bikerPhone2, bikerPhone3, bikerPhone1].map((image) =>
            createImg(image)
          )}
        </div>
      </div>
    </div>
  );
}
