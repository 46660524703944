import { useEffect } from "react";

export default function Observer({ children, sectionRef, callback }) {
  function onObserver(entries) {
    const entry = entries[0];
    console.log(entry);
    if (entry.isIntersecting) {
      callback(sectionRef.current.id);
      console.log("element in:", entry.target.id);
    } else {
      console.log("element left:", entry.target.id);
    }
  }

  useEffect(() => {
    const refCopy = sectionRef;

    let options = {
      root: null,
      rootMargin: "0% 0% -5% 0%",
      threshold: [0.5],
    };

    let observer = new IntersectionObserver(onObserver, options);

    if (refCopy.current) {
      observer.observe(refCopy.current);
    }
    return () => {
      if (refCopy.current) {
        observer.unobserve(refCopy.current);
      }
    };
  }, [sectionRef]);

  return <div id="observation">{children}</div>;
}
