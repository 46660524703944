import { useRef } from "react";
import Observer from "../observer/observer.component";
import Card from "./card.component";
import "./projects.style.scss";
import bikerPhone from "../../assets/bikerPhone2.jpg";
import macImage from "../../assets/mac03.jpg";
import feedniLight from "../../assets/feedniLight.png";
import BikerRouter from "../pages/bikeRouter.component";
import BikerPhone from "../pages/bikerPhone.component";

export default function Projects({ handleInView }) {
  const sectionRef = useRef(null);

  return (
    <Observer sectionRef={sectionRef} callback={handleInView}>
      <section
        ref={sectionRef}
        id="projects"
        className="projects"
        data-scroll-section
        data-scroll
        data-scroll-class="cool-background"
      >
        <Card url="biker-web">
          <div>
            <h3 className="card-title">Bike Router</h3>
            <div className="card-description">
              <p>
                A <em>React</em> app for planning and analyzing the path of a
                cycling trip.
              </p>
              <p>
                I started working on this app as I wanted to know the
                inclination of one really difficult biking route I took. Many
                apps offer this info but none would let me select the exact part
                of the road for which inclination must be calculated...
                <a href="#">read more</a>
              </p>
            </div>
          </div>
          <img src={macImage} alt="cycling app" />
        </Card>

        <Card url="biker-phone">
          <div>
            <h3 className="card-title">Biker Phone</h3>
            <div className="card-description">
              <p>
                A <em>React Native </em>mobile app that helps cycling lovers
                find new routes.
              </p>
              <p>
                After building the web app, I was unhappy with the way the map
                looked on mobile. I had used Leaflet maps which work great on
                the desktop browser but on the mobile they get over-compressed.
                The solution was to create a native app using React Native. It
                was a bitter sweet...
                <a href="#">read more</a>
              </p>
            </div>
          </div>
          <img src={bikerPhone} alt="cycling app" />
        </Card>
        <Card url="feedni">
          <div>
            <h3 className="card-title">Feedni</h3>
            <div className="card-description">
              <p>
                A fullstack <em>NextJS</em> content-aggregator web application.
              </p>
              <p>
                I love reading posts from different blogs and magazines, To keep
                everything in one place I used a paid software that had a
                limited free tier. I decided to build this app as a free
                alternative. It is a fullstack app with Postgres DB and NextJS.
                A fair bit of web scraping was also involved to retrieve info
                about th...
                <a href="#">read more</a>
              </p>
            </div>
          </div>
          <img src={feedniLight} alt="cycling app" />
        </Card>
      </section>
    </Observer>
  );
}
